import React from 'react';
import ImageBackground from './ImageBackground';

export default function Home() {

    return (
        <div className="section top lazy purple">

            <ImageBackground />

            <div className="container hero-parallax" style={{ top: 0 }}>
                <div className="sixteen columns">

                    <h1 className="title">
                        CDE Strategies
                    </h1>

                    <p className="tagline">
                        <b>CDE Strategies</b> is a three-woman owned consulting firm offering a wide variety of fundraising services for political campaigns, political organizations, political action committees, and nonprofits. With over 45 years of combined experience, we have built a reputation of delivering results with professional execution. We believe that fundraising is not a one-size-fits-all industry and prioritize integrating fully into each campaign or organization to act as a partner in success. All work is done by three senior partners and not junior staff.
                    </p>

                </div>

                <div className="center">
                    <div className="ten columns offset-three">
                        <h3>We&prime;d love to work with you</h3>
                        <p className="tagline">
                            Please email us: <a href="mailto:info@cdestrat.com">info@cdestrat.com</a>
                        </p>
                    </div>
                </div>

            </div>



            <div className="sep-dual sep-gray"></div>
            <div className="arrow"></div>
        </div>
    );

}
