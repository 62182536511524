import React from 'react';
import ImageBackground from './ImageBackground';

export default function Staff() {

    return (
        <div className="section features gray no-border-top reduce-top-padding">

            <ImageBackground />

            <div className="container">
                <div className="clearfix">
                    <div className="sixteen columns center">
                        <h2>Who We Are</h2>
                    </div>
                </div>
                <div className="features-list clearfix">
                    <div className="third columns">
                        <div className="description">
                            <h3>Diana Fassbender</h3>
                            <p>
                                With over 2 decades of experience as a fundraiser, political strategist, and donor advisor, Diana has worked with first time candidates and incumbent elected officials up and down the ballot, start-up and establishment political organizations, political philanthropists, and progressive nonprofits.
                            </p>
                        </div>
                    </div>
                    <div className="third columns">
                        <div className="description">
                            <h3>Emily Ison Hawkins</h3>
                            <p>
                                Emily has been primarily focused on in-house US Senate fundraising for over ten years. She has served as finance director or interim finance director on seven races of varying sizes since 2017. In the 2024 cycle, she ran the major donor experience at the Chicago convention for the presidential ticket.
                            </p>
                        </div>
                    </div>
                    <div className="third columns">
                        <div className="description">
                            <h3>Caroline Saudek</h3>
                            <p>
                                With 15 years of experience, Caroline has helped raise hundreds of millions of dollars for major cultural institutions, start-up nonprofits, and record-breaking political campaigns.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
