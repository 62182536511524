import React from 'react';

export default function Services() {

    return (
        <div className="section gray no-border-top reduce-top-padding">
            <div className="container">
                <div className="sixteen columns center clearfix">
                    <div className="title-group">
                        <h2>Our Services</h2>
                    </div>
                </div>
                <div className="profile-row">
                    <div className="seven offset-one columns profile-column">
                        <div className="profile fixed-center">
                            <div className="info">
                                <h4>East Coast Fundraising Services</h4>
                                <p>
                                    For campaigns, political organizations, political action committees, and nonprofits looking to increase their major donor fundraising.
                                </p>
                            </div>

                            <div className="info">
                                <h4>Campaign Launch Services</h4>
                                <p>
                                    For candidates looking to kick-off their campaigns.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="seven offset-one columns profile-column">
                        <div className="profile fixed-center">
                            <div className="info">
                                <h4>Interim Finance Director and Senior Fundraising Advisor Services</h4>
                                <p>
                                    For campaigns, political organizations and nonprofits looking to establish a top tier fundraising shop.

                                </p>
                            </div>

                            <div className="info">
                                <h4>Political Advising and Philanthropy</h4>
                                <p>
                                    For individuals, families, and foundations looking to maximize their impact.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );

}
