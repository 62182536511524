import React from 'react';
import ImageBackground from './ImageBackground';

export default function Staff() {

    return (
        <div className="section features lazy purple">

        <ImageBackground />

        <div className="container">
            <div className="clearfix">
                <div className="profile-row justify-content-center">
                    <div>
                        <h2>Previous and Current Work</h2>
                        <ul>
                            <li><p>Democratic Attorneys General Association</p></li>
                            <li><p>EMILY&prime;s List</p></li>
                            <li><p>Senator Jon Ossoff</p></li>
                            <li><p>Congresswoman Debbie Mucarsel Powell</p></li>
                            <li><p>Lucas Kunce</p></li>
                            <li><p>Congresswoman Cindy Axne</p></li>
                            <li><p>School Board Member Liz Whitmer Gereghty</p></li>
                            <li><p>State Senator Jen Jordan</p></li>
                            <li><p>Congresswoman Abby Finkenauer</p></li>
                            <li><p>Judge Lina Hidalgo</p></li>
                            <li><p>Secretary John King</p></li>
                            <li><p>Attorney General Jeff Jackson</p></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

}
